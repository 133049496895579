import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../auth.service';
import { MessageService } from '../message.service';
import { RouterLink, Router } from '@angular/router';
import { MetadataService } from '../MetadataService';
import { BaseService } from '../BaseService';
import { Observable } from 'rxjs';
import { query } from '@angular/animations';
import { environment } from '../../environments/environment'
// import { connect } from 'net';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  loginForm: FormGroup;
  isSubmitted = false;
  message: any;
  errormessage: boolean;
  metadata: any;
  isSigned: boolean;
  showLoading = true;
  isTouch = 'ontouchstart' in window;
  messageModal: string = "Για να έχετε πρόσβαση στην λίστα των υποψηφίων για εργασία πρέπει να έχετε συνδεθεί ως επιχείρηση.";
  epimelitirio: string;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private metadataService: MetadataService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe((res) => {
      this.isSigned = res;
      this.epimelitirio = environment.environment;
      if (this.isSigned && this.authService.isCompany()) {
        this.authService.getCompanyInfo(this.authService.getCompanyId()).subscribe((data) => {
          this.authService.isValidCompany = data.isValid;
          this.authService.signedName = data.name;
        });
      }
      else if (this.isSigned && this.authService.isCandidate()) {
        this.authService.getCandidateProfile().subscribe((data) => {

          this.authService.isValidCandidate = data.isvalid;
          this.authService.signedName = data.firstname + " " + data.lastname;
        });
      }
    })
  }

  jobsList() {
    this.router.navigate(['job-listing']);
  }
  jobListCompany() {
    if (this.authService.isValidCompany) {
      this.router.navigate(['/employers/job-listing', "all"]);
    }

  }
  jobPost() {
    this.router.navigate(['employers/post-a-job']);
  }
  candidatesList() {
    this.router.navigate(['/candidates/list']);
  }
  onUnsignedCandidatesList() {
    this.messageService.setMessageForLoginAndRegisterModal(this.messageModal);
    $('#MessageModal').modal('show');
  }

  myAccount() {
    if (this.authService.isCompany()) {
      this.authService.getCompanyInfo(this.authService.getCompanyId()).subscribe((data: any) => {
        if (data.isValid === false) {
          this.router.navigate(['employers/complete-profile']);
        }
        else {
          this.router.navigate(['/employer/profile/',this.authService.getCompanyId()]);
        }
      });
    }
    else {
      this.authService.getCandidateProfile().subscribe((data: any) => {
        if (data.isvalid === false) {
          this.router.navigate(['candidates/complete-profile']);
        }
        else {
          this.router.navigate(['candidate/profile', this.authService.getUUID()]);
        }
      });
    }
  }
  myApplies() {
    this.router.navigate(['candidates/applied-job-list']);
  }
  myConfig() {
    this.router.navigate(['candidates/newsletter-preferences'])
  }
  cancel() {
    this.authService.logoutCompany();
    this.authService.logoutCandidateOrCompany();
    this.authService.logoutMiscInfo();
    this.router.navigate(['home']);
  }

  // modaOptions LoginModal/SignUpModal
  openSignUpOrLoginModal(modaloption){

    // reset form 
    $(modaloption).on('hidden.bs.modal', function () {
      $(this).find('form').trigger('reset');
    });

    // hide error message
    $(modaloption).on('hidden.bs.modal', function () {
      $(".modal-body #errorMessage").hide();
    });

    // shows modal
    $(modaloption).modal("show");
  }

}




