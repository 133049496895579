import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../auth.service';
import { MatSnackBar } from "@angular/material";

@Component({
    selector: 'app-login-facebook',
    templateUrl: './login-facebook.component.html',
    styleUrls: ['./login-facebook.component.css']
})

export class LoginFacebookComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private _snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        const code = this.route.snapshot.queryParamMap.get('code');

        this.authService.getCandidateDataFromFacebook(code).subscribe((res) => {
            this.authService.isValidCandidate = res.isValid;

            if (this.authService.isValidCandidate)
                this.router.navigate(["home"]);
            else 
                this.router.navigate(["candidates/complete-profile"]);
        },
        error => {
            if (error.status === 400) {
                const statusMessage = "Η σύνδεση απέτυχε. Φαίνεται ότι έχετε ήδη λογαριασμό με αυτό το email";
                const panelStyle = 'error-dialog';

                this._snackBar.open(statusMessage,  null, {
                    duration: 5000,
                    verticalPosition: 'top', horizontalPosition: 'center',
                    panelClass: [panelStyle]
                });
            }
        })
    }
}