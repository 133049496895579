import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-login-linked-in',
  templateUrl: './login-linked-in.component.html',
  styleUrls: ['./login-linked-in.component.css']
})
export class LoginLinkedInComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private cookieService:CookieService,
    private authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    let code = this.route.snapshot.queryParamMap.get('code');

    // if(state === this.cookieService.get('linkedInState')){
        
      this.authService.getCandidateDataFromLinkedIn(code).subscribe((res)=>{
        this.authService.isValidCandidate = res.isValid;
          if(this.authService.isValidCandidate){
            this.router.navigate(["home"]);
          }
          else {            
            this.router.navigate(["candidates/complete-profile"]);
          }
      },
      error =>{
        if(error.status === 400){
          let statusMessage="Η σύνδεση απέτυχε φαινεται οτι έχετε ήδη λογιαριασμο με αυτό το Email";
          let panelStyle = 'error-dialog';
          this._snackBar.open(statusMessage, null, {
            duration: 5000,
            verticalPosition: 'top', horizontalPosition: 'center',
            panelClass: [panelStyle]
          });    
        }
      })
    // }
  }
}
